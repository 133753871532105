/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import styles with default layout.
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

/* Core Grid CSS */
@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-quartz.css';


html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: var(--cui-body-font-family) !important;
}


.ag-ltr .ag-cell-focus {
    border: 0 !important;
}
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
